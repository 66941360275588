<template>
  <layout
    :title="$vuetify.breakpoint.lgAndUp ? 'Minha Assinatura' : 'Assinatura'"
    :options="[]"
  >
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <v-form ref="formMinhaLoja">
          <v-flex xs12>
            <div class="fonte expande-horizontal centraliza column wrap">
              <h3 :style="`color: ${$theme.secondary}`" class="fonte">
                Plano {{ subscription.plan_id.name }}
              </h3>
              <span class="fonte fonteMini grey--text"
                >Próxima cobrança:
                {{
                  $moment(subscription.creation_of_next_charge).format(
                    "DD/MM/YYYY"
                  )
                }}</span
              >
              <div class="expande-horizontal pt-3 centraliza">
                <v-flex xs3 md1>
                  <div class="expande-horizontal centraliza">
                    <div
                      icon
                      @click=""
                      :class="{
                        'method-selected': subscription.method === 'bank_slip'
                      }"
                      class="change-method-icon-container"
                      :color="$theme.secondary"
                    >
                      <v-icon
                        :color="
                          subscription.method === 'bank_slip'
                            ? 'white'
                            : $theme.secondary
                        "
                        >mdi-barcode</v-icon
                      >
                      <span
                        :class="{
                          'white--text': subscription.method === 'bank_slip',
                          'grey--text': subscription.method === 'card'
                        }"
                        class="fonteMini"
                        >Boleto</span
                      >
                    </div>
                  </div>
                </v-flex>
                <v-flex xs3 md1>
                  <div class="expande-horizontal centraliza">
                    <div
                      icon
                      @click=""
                      :class="{
                        'method-selected': subscription.method === 'card'
                      }"
                      class="change-method-icon-container"
                      :color="$theme.secondary"
                    >
                      <v-icon
                        :color="
                          subscription.method === 'card'
                            ? 'white'
                            : $theme.secondary
                        "
                        >mdi-credit-card-outline</v-icon
                      >
                      <span
                        :class="{
                          'white--text': subscription.method === 'card',
                          'grey--text': subscription.method === 'bank_slip'
                        }"
                        class="fonteMini"
                        >Cartão</span
                      >
                    </div>
                  </div>
                </v-flex>
              </div>
            </div>
            <div class="pa-6 centraliza expande-horizontal wrap">
              <v-flex xs12 md8>
                <v-list dense class="pa-0 ma-0 fonte expande-horizontal">
                  <v-list-item
                    class="item-list-charge mb-3"
                    v-for="charge in charges"
                    :key="charge._id"
                  >
                    <v-avatar class="mr-3" :color="$theme.secondary">
                      <v-icon color="white">mdi-receipt-text-outline</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="white--text font-weight-bold">
                        {{ $helper.formataSaldo(charge.value / 1000) }}
                      </v-list-item-title>
                      <v-list-item-title class="white--text">
                        Cobrado
                        {{ $moment(charge.charge_fire).format("DD/MM/YYYY") }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="white--text">
                        Situação: {{ charge.current_status }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        text
                        color="white"
                        style="opacity: 0.8"
                        class="font-weight-bold"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
          </v-flex>
        </v-form>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import LottieAnimation from "lottie-web-vue";

export default {
  data() {
    return {
      moment: moment,
      coverImage: "https://via.placeholder.com/1600x400",
      profileImage: "https://via.placeholder.com/512x512",
      coverProgress: 0,
      profileProgress: 0,
      cropCoverImage: false,
      cropCoverImageData: "",
      cropProfileImage: false,
      cropProfileImageData: "",
      option: {
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 512,
        autoCropHeight: 512,
        centerBox: false,
        high: false,
        max: 99999
      },
      option2: {
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 120,
        autoCropHeight: 120,
        centerBox: false,
        high: false,
        max: 9999
      },
      fixed: true,
      fixedNumber: [16, 4],
      fixed2: true,
      fixedNumber2: [1, 1],
      profile: {},
      charges: {},
      subscription: {}
    };
  },
  components: {
    VueCropper,
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    daysLeft() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      const days = moment(expires).diff(moment(), "days");
      return days;
    },
    dateExpire() {
      const expires =
        this.getLoggedUser.tenant[0].active_plan.expires_at || new Date();
      return moment(expires).format("DD/MM/YYYY [às] HH:mm");
    }
  },
  filters: {
    filterStatusSubscription(val) {
      switch (val) {
        case "active":
          return "ativa";
        default:
          return "";
      }
    }
  },
  methods: {
    openUploadProfileContainer() {
      this.$refs.image_profile_upload.click();
    },
    openUploadCoverContainer() {
      this.$refs.cover_upload.click();
    },
    cropMoving() {},
    cropImg() {},
    imgLoad() {},
    realTime() {},
    preUploadCoverImage() {
      const input = this.$refs.cover_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropCoverImageData = e.target.result;
          this.cropCoverImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    preUploadProfileImage() {
      const input = this.$refs.image_profile_upload;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropProfileImageData = e.target.result;
          this.cropProfileImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    async uploadCoverImage() {
      this.$refs.cropper10.getCropBlob(async data => {
        this.coverImage = data;
        const file = new Image();
        file.src = data;
        const storageRef = this.$storage.ref(
          `cover_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = storageRef.put(data);
        task.on(
          "state_changed",
          snapshot => {
            this.coverProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            this.coverImage = await task.snapshot.ref.getDownloadURL();
            this.saveProfileImage("coverImage");
          }
        );
      });
    },
    async uploadProfileImage() {
      this.$refs.cropper20.getCropBlob(async data => {
        this.profileImage = data;
        const file = new Image();
        file.src = data;
        const storageRef = this.$storage.ref(
          `profile_images/${this.getLoggedUser.activeTenant._id}.png`
        );
        const task = storageRef.put(data);
        task.on(
          "state_changed",
          snapshot => {
            this.profileProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error(error);
          },
          async () => {
            this.profileImage = await task.snapshot.ref.getDownloadURL();
            this.saveProfileImage("profileImage");
          }
        );
      });
    },
    async getMyProfile() {
      this.loading = true;
      this.$run("marketplaces/show-my-profile")
        .then(res => {
          if (res.data.profile.coverImage) {
            this.coverImage = res.data.profile.coverImage;
          }
          if (res.data.profile.profileImage) {
            this.profileImage = res.data.profile.profileImage;
          }
          this.subscription = res.data.subscription;
          this.profile = res.data.profile;
          this.charges = res.data.charges;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    async saveProfileImage(type) {
      this.loading = true;
      let data = {};
      data[type] = this[type];
      this.$run("marketplaces/set-profile-image", data)
        .then(() => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  created() {
    this.getMyProfile();
  }
};
</script>

<style>
.store-layout {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  cursor: pointer;
}

.store-cover {
  width: 100%;
  min-height: 220px;
  border-radius: 6px;
}

.profile-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.store-profile {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-list-charge {
  background: #3eb4df;
  border-radius: 6px;
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
.change-method-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
}
.method-selected {
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  background: #3eb4df;
}
</style>
